<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OFFICIAL RECEIPT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="transmital_no"
            class="mx-3"
            :items="transmital_no_items"
            item-value="id"
            item-text="transmital_no"
            label="Transmital #"
            required
            @change="transmital_selected"
            dense
          ></v-select>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="category"
            class="mx-3"
            :items="['Not Used','Used','Done']"
            label="Category"
            required
            @change="transmital_selected"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="transmital_data"
      >
        <template v-slot:item="{ item }">
          <tr
            v-if="category==='' || (category==='Done'&&item.done===1)||(category==='Used'&&item.done===0 && item.collector_id!=null)
||(category==='Not Used'&&item.done===0 && item.collector_id===null)"
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td class="text-center">{{ item.transmital_no }}</td>
            <td class="text-center">
              {{ item.stab_no }}
            </td>
            <td class="text-center">
              {{ item.start+' - '+item.end }}
            </td>
            <td class="text-center">
              {{ item.address }}
            </td>
            <td class="text-center">
              {{ item.date_release}}
            </td>
            <td class="text-center">
              {{ item.collector_name}}
            </td>
            <td class="text-center">
              {{ item.last_used}}
            </td>
            <td class="text-center" v-if="item.done===1">
              <v-chip color="success" dark>DONE</v-chip>
            </td>
            <td class="text-center" v-else-if="item.done===0 && item.collector_id!=null">
              <v-chip color="error" dark>USED</v-chip>
            </td>
            <td class="text-center" v-else>
              <v-chip color="info" dark>NOT USED</v-chip>
            </td>
            <td class="text-center">
              {{ item.remarks}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.official_receipt_monitoring(data)
        .then(response => {
          this.transmital_no_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'TRANSMITAL #', value: 'id', sortable: false},
          {text: 'STAB #', value: 'date_of_deposit', sortable: false},
          {text: 'OR SERIES', value: 'time_of_deposit', sortable: false},
          {text: 'OR ADDRESS', value: 'amount', sortable: false},
          {text: 'DATE  RELEASED', value: 'bank_code_name', sortable: false},
          {text: 'COLLECTOR', value: 'bank_code_name', sortable: false},
          {text: 'LAST USED', value: 'bank_code_name', sortable: false},
          {text: 'STATUS', value: 'bank_code_name', sortable: false},
          {text: 'REMARKS', value: 'bank_code_name', sortable: false},],

        transmital_data: [],
        transmital_no_items: [],
        transmital_no: '',
        selectedDepositId: -1,
        category: '',
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deposit_slips', ['remittances_data', 'delete_remittances']),
      ...mapActions('transmital_or', ['official_receipt_monitoring']),
      transmital_selected() {
        var index = this.transmital_no_items.map(function (x) {
          return x.id;
        }).indexOf(this.transmital_no)
        if (index != -1) {
          this.transmital_data = this.transmital_no_items[index].data
        }
      },
      activerow(value) {
        this.selectedDepositId = value.id;
      },
    }
  }
</script>
